import { cva } from "class-variance-authority";

export const root = cva("flex font-medium border-b border-stroke/10", {
  variants: {
    size: {
      xs: "text-xs h-6 space-x-2",
      sm: "text-sm h-8 space-x-5",
      md: "text-sm h-10 space-x-5",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export const item = cva("block border-b px-2 mb-[-1px] text-content hover:text-contrast", {
  variants: {
    active: {
      false: "border-transparent",
      true: "text-primary border-primary hover:text-primary",
    },
  },
  defaultVariants: {
    active: false,
  },
});

export const tabList = { root, item };
