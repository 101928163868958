import { type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { cn } from "../utils";
import { Slot } from "@radix-ui/react-slot";
import { tabList } from "./tab-list.styles";

// Re-export the styles for use with framework components.
export { tabList };

interface TabListProps extends VariantProps<typeof tabList.root>, React.HTMLAttributes<HTMLDivElement> {}

/**
 * Basic dumb component for a list of static tabs.
 */
export const TabList = forwardRef<HTMLDivElement, TabListProps>(({ size, className, ...props }, ref) => {
  return <div className={cn(tabList.root({ size }), className)} ref={ref} {...props} />;
});

TabList.displayName = "TabList";

interface TabListItemProps extends VariantProps<typeof tabList.item>, React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
}

export const TabListItem = forwardRef<HTMLButtonElement, TabListItemProps>(
  ({ asChild, active, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(tabList.item({ active }), className)} ref={ref} {...props} />;
  },
);

TabListItem.displayName = "TabListItem";
